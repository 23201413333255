.training{
    max-width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    height: 100%;
    align-items: center;
}

@media screen and(max-width: 980px) {
    .training{
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }
    .training .img{
        max-width: 80%;
    }  
}