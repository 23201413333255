@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.header, .header-bg {
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
    transition: background-color 0.5s;
    top: 0;
    left: 0;
}

.header-bg {
    background-color: rgba(0, 0, 0, 0.85);
}

.logo {
    height: 60px; /* Adjust the size as needed */
    margin-top: 25px;
    margin-left: 20px;
}

.nav-menu {
    display: flex;
    align-items: center;
    margin-right: 20px; /* Add margin to avoid overlapping with hamburger */
}

.nav-menu li {
    padding: 0 1rem;
}

.nav-menu a {
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff; /* Ensure links are visible */
    text-decoration: none; /* Remove underline */
}

.nav-menu a:hover {
    color: #f0f0f0; /* Lighten color on hover for better visibility */
}

.hamburger {
    display: none;
}

@media screen and (max-width: 1240px) {
    .hamburger {
        display: block;
    }

    .nav-menu {
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: -100%;
        text-align: center;
        width: 100%;
        height: 100vh;
        transition: 0.3s;
        z-index: 11; /* Ensure it’s above other content */
        background: rgba(0, 0, 0, 0.9);
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-menu li {
        padding: 1rem 0;
    }

    .nav-menu a {
        font-size: 2rem;
    }
}
