@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#video {
    position: fixed;
    width:100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.hero {
    height: 100vh;
    width: 100%;
    object-fit: contain;
}

.hero .content {
    text-align: center;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    padding:0;
    top: 78vh; /* Adjusted top value to move the content lower */
    transform: translateY(-50%); /* Optional: This can help vertically center the content within its container */
}

.hero .content h1, .hero .content h2{
    line-height: 80px;
    letter-spacing: -1.44px;
    background: linear-gradient(to right, 
                        transparent,  
                        rgb(92, 111, 254),
                        rgb(209, 87, 243) 43%,
                        rgb(250, 169, 108),
                        rgb(250, 169, 108)
                    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 120% 100%;
    padding:0;
    animation: gradientRotate 5s linear infinite;
}

@keyframes gradientRotate {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 100%;
    }
}

.hero .content h1 {
    font-size: 4.5rem;
    font-weight: 550;
    padding-bottom: 3px;
}
.hero .content h2 {
    font-size: 4.2rem;
    background:rgb(219, 217, 217);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Roboto', sans-serif;
    font-weight: 250;
}

.hero p{
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    font-weight: 150;
    text-transform: none;
    color: #e6e4e4;
    padding-top:2rem;
}

.mailing-wrapper{
    display: flex;
}
.MainForm{
    display: grid;
    grid-template-columns: 270px 100px; /* Two equal columns */
    gap: 15px; /* Space between columns */
    max-width: 100%;
    margin-bottom: 1.6rem;
    text-align: center;
}

.MainForm input {
    width: 100%;
    padding:7px 15px;
    border: 0.5px solid #414141;
    border-radius: 13px;
    background: linear-gradient(135deg, transparent, rgba(27, 25, 77, 0.8), rgba(42, 24, 63, 0.8) 43%, transparent); /* Gradient background with transparency */
    border: 0.1px solid rgba(220, 206, 229, 0.152); 
    color: #ffffff;
    font: 300 1rem 'Roboto', sans-serif;
    z-index:0;
  }

  .MainForm .btn {
    text-transform: none;
    width: auto; /* Ensure button width is auto */
    background: linear-gradient(135deg, 
                    rgb(92, 111, 254),
                    rgb(209, 87, 243) 43%,
                    rgb(250, 169, 108)
                );
    color: white;
    border: none; 
    border-radius: 13px;
    cursor: pointer;
    font: 300 1rem 'Roboto', sans-serif;
    max-width: 100%; /* Adjust width to 100% of the container if needed */
    margin: 0;
    padding: 0.7rem;
  }
  
  .MainForm .btn:hover {
    background: linear-gradient(135deg, 
                    rgb(104, 121, 249),
                    rgb(221, 117, 249) 43%,
                    rgb(243, 174, 122)
                );    font-size: 16px;
    font: 300 1rem 'Roboto', sans-serif;
    transition: background 0.3s ease-in-out, font-size 0.2s ease-in-out;
  }

@media screen and (max-width:640px) {
    .content h1 {
        font-size: 3rem;
    }
    .content h2 {
        font-size: 1rem;
    }
    .content p {
        font-size: 1.3rem;
        margin-top: 0.2rem;
    }
}










