@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.hero-img{
    background: rgb(0, 0, 0,.3);
    height: 40vh;
    width: 100%;
    position: relative;
}

.hero-img:before{
    content: '';
    background: url('../assets/bckgrndSide.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero-img .heading{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 100;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
    padding-top: 7rem;
}

.hero-img h1{
    font-size: 2.4rem;
    font-weight: 500;
}

.hero-img p{
    font-size: 1.2rem;
    padding: .6rem;
}

@media screen and(max-width:640px) {
    .hero-img h1, .hero-img p {
        font-size: 2rem;
    }
    
}